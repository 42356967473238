

































































































































































import { Vue, Component } from "vue-property-decorator";
import { Input, Button, Form } from "element-ui";
import { AdminRouter } from "@/utils/routePathContsant";
import commonstore from "@/store/modules/common";
import newsStore from "@/store/modules/news";

@Component({
  components: {
    Input,
    Button,
    Form,
  },
})
export default class New extends Vue {
  isCreate: boolean = true;
  fileList: File[] = [] as File[];

  get AdminRouter() {
    return AdminRouter;
  }
  get thenews() {
    return newsStore.thenews;
  }

  get newsCategory() {
    return newsStore.newsCategoryList;
  }

  get newsType() {
    return newsStore.newsTypeList;
  }
  handleChange(file: any, fileList: any) {
    let vm = this;
    vm.fileList = [file.raw];
    vm.thenews.FileList = file.raw;
  }
  handleRemove(file: any, fileList: any) {
    let vm = this;
    vm.fileList = [] as File[];
    vm.thenews.FileList = [] as File[];
  }

  async submit() {
    let isValid = await this.$validator.validateAll();
    if (!isValid) {
      return;
    }
    let vm = this;
    commonstore.showLoading();

    await newsStore.createNews();
    this.$snotify.success("News  Saved Successfully");
    commonstore.hideLoading();
    this.$router.push(AdminRouter.NewsList);
  }

  async cancelForm() {
    this.$router.push(AdminRouter.NewsList);
  }

  async created() {
    let vm = this;
    const id = vm.$route.query["id"];
    if (id) {
      vm.isCreate = false;
      newsStore.getAllNewsCategory();
      newsStore.getAllNewsTypes();
      await newsStore.loadNewsById(id);
    } else {
      newsStore.resetField();
      newsStore.getAllNewsCategory();
      newsStore.getAllNewsTypes();
    }
  }

  mounted() {}
  updated() {}
}
